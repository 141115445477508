import { Done } from "@mui/icons-material";
import { Avatar, Card, CardContent, Container, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

const PaymentSuccess = () => {
    let { payment_id } = useParams();
    return (
        <Container maxWidth="sm" sx={{ mt: 4 }}>
            <Card variant="outlined">
                <CardContent>
                    <Stack alignItems={'center'} spacing={2}>
                        <Avatar sx={{ backgroundColor: 'green', width: 80, height: 80 }}>
                            <Done fontSize="large" />
                        </Avatar>
                        <Typography variant="h4">Payment Successful</Typography>
                        <Typography variant="body1">Your payment reference is <strong>{payment_id}</strong></Typography>
                    </Stack>
                </CardContent>
            </Card>
        </Container>
    );
};

export default PaymentSuccess;