// src/components/PaymentMethodPage.js
import { AccountBalanceOutlined, CreditCard, CreditCardOutlined, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Card, CardActions, CardContent, Container, Divider, FormControl, FormControlLabel, Grid, ListItemText, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import React from 'react';


const PaymentMethodPage = () => {

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    // <div className="payment-method-container">
    //   <h2>Choose Payment Method</h2>
    //   <div className="payment-option">
    //     <input type="radio" id="credit-card" name="payment" defaultChecked />
    //     <label htmlFor="credit-card">Credit Card</label>
    //   </div>
    //   <div className="payment-option">
    //     <input type="radio" id="debit-card" name="payment" />
    //     <label htmlFor="debit-card">Debit Card</label>
    //   </div>
    //   <div className="payment-option">
    //     <input type="radio" id="net-banking" name="payment" />
    //     <label htmlFor="net-banking">Net Banking</label>
    //   </div>
    //   <button>Pay Now</button>
    // </div>

    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Stack spacing={2}>
            <Card variant='outlined'>
              <CardContent>
                <Typography gutterBottom variant='h4'>Choose Payment Method</Typography>

                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} variant='outlined'>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <CreditCardOutlined sx={{ mr: 2 }} />
                    <Typography sx={{ width: '100%' }}>
                      Credit/Debit Card
                    </Typography>
                    <Stack direction={'row'} spacing={2} sx={{ mr: 2 }}>
                      <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/Visa_2021.svg/1920px-Visa_2021.svg.png'} alt='visa' width={50} style={{ objectFit: 'contain' }}></img>
                      <img src={'https://upload.wikimedia.org/wikipedia/commons/b/b7/MasterCard_Logo.svg'} alt='mastercard' width={50} style={{ objectFit: 'contain' }}></img>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack rowGap={1} sx={{ width: '70%' }}>
                      <Stack spacing={1}>
                        <TextField id="outlined-basic" label="Enter Card Number" variant="outlined" fullWidth />
                        <TextField id="outlined-basic" label="Card Holder" variant="outlined" fullWidth />
                      </Stack>
                      <Grid container spacing={1}>
                        <Grid item xs={8}>
                          <TextField id="outlined-basic" label="Start" variant="outlined" type='text' fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField id="outlined-basic" label="CVV" variant="outlined" type='password' fullWidth />
                        </Grid>
                      </Grid>

                    </Stack>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} variant='outlined'>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <AccountBalanceOutlined sx={{ mr: 2 }} />
                    <Typography sx={{ width: '100%' }}>
                      NetBanking
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack rowGap={1} sx={{ width: '70%' }}>
                      <Stack spacing={1}>
                        <TextField id="outlined-basic" label="Enter Card Number" variant="outlined" fullWidth />
                        <TextField id="outlined-basic" label="Card Holder" variant="outlined" fullWidth />
                      </Stack>
                      <Grid container spacing={1}>
                        <Grid item xs={8}>
                          <TextField id="outlined-basic" label="Start" variant="outlined" type='text' fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField id="outlined-basic" label="CVV" variant="outlined" type='password' fullWidth />
                        </Grid>
                      </Grid>

                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </CardContent>
            </Card>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Card variant='outlined'>
            <CardContent>
              <Typography gutterBottom variant='h4'>Summary</Typography>
              <ListItemText primary="$122" secondary="Order Summary" />
              <ListItemText primary="$10" secondary="Additional Service" />
              <Divider></Divider>
              <ListItemText primary="$132" secondary="Total Amount" />
            </CardContent>
            <CardActions>
              <Button variant="contained" size='large' fullWidth href='/payment'>Proceed to Pay</Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

    </Container>
  );
};

export default PaymentMethodPage;
