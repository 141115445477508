import { Error } from "@mui/icons-material";
import { Avatar, Card, CardContent, Container, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

const PaymentFailed = () => {
    let { description } = useParams();
    return (

        <Container maxWidth="sm" sx={{ mt: 4 }}>
            <Card variant="outlined">
                <CardContent>
                    <Stack alignItems={'center'} spacing={2}>
                        <Avatar sx={{ backgroundColor: '#FF3333', width: 80, height: 80 }}>
                            <Error fontSize="large"/>
                        </Avatar>
                        <Typography variant="h4">Payment Failed</Typography>
                        <Typography variant="body1">Reason: <strong>{description}</strong></Typography>
                    </Stack>
                </CardContent>
            </Card>
        </Container>
    );
};

export default PaymentFailed;