/* global Razorpay */

import { Box, Button, Card, CardActions, CardContent, Container, FormControl, Radio, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ConfirmPayment = () => {
    let { amount } = useParams();
    const navigate = useNavigate();
    const [selectedValue, setSelectedValue] = useState('razorpay');
    const [orderId, setOrderId] = useState('');

    const loadRazorpayScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => {
                console.log('Razorpay script loaded');
                resolve(true);
            };
            script.onerror = () => {
                console.error('Failed to load Razorpay script');
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    const generate8DigitNumber = () => {
        const timestamp = Date.now();
        const randomFactor = Math.floor(Math.random() * 10000);
        const randomNumber = (timestamp + randomFactor).toString().slice(-8);
        return parseInt(randomNumber, 10);
    }

    useEffect(() => {
        const receiptNr = generate8DigitNumber();
        var url = "https://demo.knoxxbox.in/index.php/home/createorder";
        axios.post(url, {
            receipt: receiptNr,
            amount: amount * 100,
            currency: 'INR'
        }).then((response) => {
            console.log(response.data);
            setOrderId(response.data);
        });
    }, []);

    const handlePayment = async () => {
        const res = await loadRazorpayScript();

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }

        var options = {
            "key": "rzp_test_J1dezMCRHiTfVW",
            "amount": parseInt(amount) * 100,
            "currency": "INR",
            "name": "RSoft",
            "description": "Test transaction",
            "order_id": orderId,
            "handler": function (response) {
                console.log(response);
                navigate("/paymentsuccess/" + response['razorpay_payment_id']);
            },
            "prefill": {
                "name": "Rajesh",
                "email": "suranju@gmail.com",
                "contact": "7032055706"
            },
            "retry": {
                "enabled": false
            }
        };
        var rzp1 = new Razorpay(options);
        rzp1.on('payment.failed', function (response) {
            console.log(response);
            navigate("/paymentfailed/" + response.error.description);
        });
        rzp1.open();
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 4 }}>
            <Box>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="h5" gutterBottom>Proceed with Payment</Typography>
                        <FormControl>
                            <Stack direction={'row'} spacing={2}>
                                <Radio
                                    checked={selectedValue === 'razorpay'}
                                    // onChange={handleChange}
                                    value="razorpay"
                                    name="radio-buttons"
                                    rops={{ 'aria-label': 'A' }}
                                />
                                <img src={'https://upload.wikimedia.org/wikipedia/commons/8/89/Razorpay_logo.svg'} alt='visa' width={120} style={{ objectFit: 'contain' }}></img>
                            </Stack>

                            <Stack direction={'row'} spacing={2}>
                                <Radio
                                    checked={selectedValue === 'ccavenue'}
                                    // onChange={handleChange}
                                    value="ccavenue"
                                    name="radio-buttons"
                                    disabled
                                    rops={{ 'aria-label': 'A' }}
                                />
                                <img src={'https://hostbillapp.com/appstore/payment_ccavenue/images/logo.png'} alt='visa' width={120} style={{ objectFit: 'contain' }}></img>
                            </Stack>
                        </FormControl>
                    </CardContent>
                    <CardActions>
                        <Box padding={2}>
                            <Button variant="contained" onClick={handlePayment}>Pay Now</Button>
                        </Box>
                    </CardActions>
                </Card>
            </Box>
        </Container>
    );
};

export default ConfirmPayment;