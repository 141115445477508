// src/components/OrderSummaryPage.js
import { Box, Button, Card, CardActions, CardContent, Checkbox, Container, Divider, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, ListItemText, MenuItem, Radio, RadioGroup, Select, Stack, Typography } from '@mui/material';
import React from 'react';

const cartItems = [
  { 'img_url': 'https://via.placeholder.com/100', 'name': 'Red Shirt', 'mrp': '120', 'price': '100', 'size': 'L' },
  { 'img_url': 'https://via.placeholder.com/100', 'name': 'Yellow Shor  ts', 'mrp': '50', 'price': '49', 'size': 'L' },
];

const OrderSummaryPage = () => {
  const [selectedValue, setSelectedValue] = React.useState('razorpay');

  let formatter = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' });

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Stack spacing={2}>
            <Card variant='outlined'>
              <CardContent>
                <Typography gutterBottom variant='h5'>Cart Items</Typography>
                {cartItems.map((item) => (
                  <div key={item.name} style={{ marginBottom: 4 }}>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                      <img src={item.img_url} alt={item.name} />
                      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center' }}>
                        <Typography variant='h6'>{item.name}</Typography>
                        <Typography variant='subtitle1'>{formatter.format(item.price)}</Typography>
                        <Typography variant='subtitle2' sx={{ textDecoration: 'line-through' }} color={'red'}>{formatter.format(item.mrp)}</Typography>
                        <Typography>Size: {item.size}</Typography>
                      </Box>
                      <FormControl sx={{ minWidth: 100 }}>
                        <InputLabel id="quantity-label">Quantity</InputLabel>
                        <Select
                          labelId="quantity-label"
                          id="quantity"
                          // value={0}
                          defaultValue={1}
                          label="Quantity"
                        // onChange={handleChange}
                        >
                          <MenuItem value={1} selected>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                  </div>
                ))}

              </CardContent>
            </Card>
            <Card variant='outlined'>
              <CardContent>
                <Typography gutterBottom variant='h5'>Delivery</Typography>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="dpd"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel value="dpd" control={<Radio />} label="DPD Deliveries - ₹10.00 (Expected delivery: Friday, 28)" />
                    <FormControlLabel value="collect" control={<Radio />} label="Collect at store" />
                  </RadioGroup>
                </FormControl>
              </CardContent>
            </Card>
            <Card variant='outlined'>
              <CardContent>
                <Typography gutterBottom variant='h5'>Additional Service</Typography>
                <FormGroup>
                  <FormControlLabel control={<Checkbox checked />} label="Care+ Package - ₹10.00" />
                </FormGroup>
              </CardContent>
            </Card>
            {/* <Card variant='outlined'>
              <CardContent>
                <Typography gutterBottom variant='h5'>Choose a payment provider</Typography>
                <FormControl>
                  <Stack direction={'row'} spacing={2}>
                    <Radio
                      checked={selectedValue === 'razorpay'}
                      onChange={handleChange}
                      value="razorpay"
                      name="radio-buttons"
                      rops={{ 'aria-label': 'A' }}
                    />
                    <img src={'https://upload.wikimedia.org/wikipedia/commons/8/89/Razorpay_logo.svg'} alt='visa' width={120} style={{ objectFit: 'contain' }}></img>
                  </Stack>

                  <Stack direction={'row'} spacing={2}>
                    <Radio
                      checked={selectedValue === 'ccavenue'}
                      onChange={handleChange}
                      value="ccavenue"
                      name="radio-buttons"
                      disabled
                      rops={{ 'aria-label': 'A' }}
                    />
                         <img src={'https://hostbillapp.com/appstore/payment_ccavenue/images/logo.png'} alt='visa' width={120} style={{ objectFit: 'contain' }}></img>
                  </Stack>
                </FormControl>
              </CardContent>
            </Card> */}
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Card variant='outlined'>
            <CardContent>
              <Typography gutterBottom variant='h5'>Summary</Typography>
              <ListItemText primary={formatter.format(149)} secondary="Order Summary" />
              <ListItemText primary={formatter.format(10)} secondary="Additional Service" />
              <Divider></Divider>
              <ListItemText primary={formatter.format(159)} secondary="Total Amount" />
            </CardContent>
            <CardActions>
              <Button variant="contained" size='large' fullWidth href='/confirm/159'>Proceed to Payment</Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      
    </Container>

  );
};

export default OrderSummaryPage;
