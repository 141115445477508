// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import OrderSummaryPage from './components/OrderSummary';
import PaymentMethodPage from './components/PaymentMethod';
import './style.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { AppBar, Badge, Button, createTheme, IconButton, Stack, ThemeProvider, Toolbar, Typography } from '@mui/material';
import { ShoppingCart } from '@mui/icons-material';
import ConfirmPayment from './components/ConfirmPayment';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentFailed from './components/PaymentFailed';

const navItems = ['Home', 'Mens', 'Womens', 'Kids'];

const theme = createTheme({
  components: {
    // Name of the component ⚛️
    MuiButtonBase: {
      defaultProps: {
        // The default props to change
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
  },
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div>

          <AppBar component="nav" color='transparent' position='static' elevation={0}>
            <Toolbar>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
              >
                Shop
              </Typography>
              <Stack spacing={1} direction="row"
                sx={{ display: { xs: 'none', sm: 'block' } }}>
                {navItems.map((item) => (
                  <Button key={item} color='inherit'>
                    {item}
                  </Button>
                ))}
                <IconButton aria-label="cart" href='/cart'>
                  <Badge badgeContent={4} color="primary">
                    <ShoppingCart />
                  </Badge>
                </IconButton>
                <Button variant='outlined'>Login</Button>
                <Button variant='contained'>Sign Up</Button>
              </Stack>
            </Toolbar>
          </AppBar>
          <main>
            <Routes>
              <Route path="/cart" element={<OrderSummaryPage />} />
              <Route path="/payment" element={<PaymentMethodPage />} />
              <Route path="/confirm/:amount" element={<ConfirmPayment />} />
              <Route path="/paymentsuccess/:payment_id" element={<PaymentSuccess />} />
              <Route path="/paymentfailed/:description" element={<PaymentFailed />} />
            </Routes>
          </main>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
